import React from "react";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Hero from "common/hero";
import Seo from "common/seo";
import CategoryDetails from "common/category-details";
import AccordionContent from "common/accordion";
import { TypeA } from "common/callouts";

import heroImageRef from "images/sun/sunproducts/sunproducts-hero.webp";
import img1 from "images/sun/freeradicalsdefense/free-radical-defense-front.webp";
import img2 from "images/sun/freeradicalsdefense/chart-visible-light.webp";
import img3 from "images/callouts/resources2x.webp";
import img4 from "images/callouts/cout-footer-daily-skin.webp";
import img5 from "images/callouts/cout-footer-journal.webp";
import img6 from "images/sun/freeradicalsdefense/oilcontrol-research-results.webp";

import "./freeradicaldefense.scss";

const MyHero = () => <Hero className="inner-hero" bgImagePath={heroImageRef} />;

function FreeRadicalDefense({ pageContext }) {
  return (
    <Layout
      pageClass="page-sun-free-radical-defense page-level-3"
      breadcrumbData={pageContext.breadcrumb}
      hero={<MyHero />}
    >
      <Seo
        pageTitle="Photoaging Defense / 5 AOX Shield"
        pageDescription="Learn about how to defend yourself from photoaging and the impacts of the 5 AOX Shield"
      />
      <div className="inner-body-content">
        <div className="top-content-level-3">
          <section className="inner-centered-container">
            <CategoryDetails
              headBgColor="#00136F"
              headText="Free Radical defense"
              infoText="Help guard against damage from oxidative stress"
              bodyText="Help your patients look their best by encouraging daily sunscreen use with Eucerin<sup>&reg;</sup> Sun. Enriched with a unique antioxidant complex, our sunscreen formulas help prevent the visible signs of photoaging, such as fine lines and wrinkles.*"
              productInfoImg={img1}
              productInfoImgAlt="5 AOX Shield™ from Eucerin Sun"
            >
              <h5 className="dark-blue">5 AOX Shield™ from Eucerin Sun</h5>
              <p>Helps defend against damage from oxidative stress</p>
              <ul className="blue-bullets">
                <li>
                  <span>
                    <span className="font-bold">All 5 antioxidants:</span> Help
                    neutralize free radicals
                  </span>
                </li>
                <li>
                  <span>
                    <span className="font-bold">
                      Vitamin C, vitamin E, and Oxynex ST:
                    </span>{" "}
                    Work together to help guard against free radical formation
                  </span>
                </li>
                <li>
                  <span>
                    <span className="font-bold">
                      Licochalcone A and Oxynex ST:
                    </span>{" "}
                    Help defend against natural antioxidant breakdown
                  </span>
                </li>
              </ul>
            </CategoryDetails>
            <Row>
              <Col xs={12}>
                <p className="references-symbols last">
                  *Use as directed with other sun protection measures (see Drug
                  Facts Box).
                </p>
              </Col>
            </Row>
          </section>
        </div>
        <div className="gradient-bg">
          <section className="inner-centered-container" id="resultsresults">
            <AccordionContent
              accordionHeading="Free Radical Defense"
              productThemeColor="eucerin-blue"
              id="freeradicaldefense"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img2}
                    className="accordion-chart"
                    alt="Showing defense using 5AOX shield"
                  />
                  <p>
                    In addition to UVA/UVB, visible light (~50% of sunlight that
                    reaches the skin) generates free radicals. Without
                    protection from the sun’s damaging rays, every skin tone is
                    vulnerable to its photoaging effects.
                  </p>
                  <p className="references-symbols first last">
                    *Use as directed with other sun protection measures (see
                    Drug Facts Box).{" "}
                  </p>
                  <p className="references">
                    <span className="font-bold">Reference: 1.</span> Lim H,
                    Kohli I, Ruvolo E, Kolbe L, Hamzavi I. Impact of visible
                    light on skin health: the role of antioxidants and free
                    radical quenchers in skin protection.{" "}
                    <em>J Am Acad Dermatol</em>. 2022;86(3):S27-S37.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    Discuss photoaging with patients as another reason to use
                    daily sunscreen
                  </div>
                </Col>
              </Row>
            </AccordionContent>
            <AccordionContent
              accordionHeading="Research Results"
              productThemeColor="eucerin-blue"
              id="researchresults"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img6}
                    className="accordion-chart"
                    alt="Showing defense using 5AOX shield"
                  />
                  <p className="footnotes">
                  <span className="font-bold">Study design:</span> 10 subjects with FST IV-VI enrolled (9 completed the study), with exposure of 380 J/cm<sup>2</sup> of 2% UVA1 and visible light with pretreatment 24 hours prior to exposure and product reapplication 20 minutes prior to exposure. Assessments conducted immediately, 24 hours, and 7 days after exposure by diffuse reflectance spectroscopy, cross-polarized images, and expert grading.
                </p>
                  <p className="references">
                    <span className="font-bold">Reference: 2.</span> Data on file. Beiersdorf Inc.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                  Provides protection beyond <br />
                  broad-spectrum
                  </div>
                </Col>
              </Row>
            </AccordionContent>
          </section>
        </div>
        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img3}
                  imgAlt="Resources for Sun Care"
                  caption="Find resources to support your
                    sun care recommendations"
                  buttonText="Browse Resources"
                  buttonUrl="/resources"
                  imgStyles={{ height: 158 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img4}
                  imgAlt="Explore Body Care"
                  caption="Clinically proven therapeutic
                    solutions for daily skin care"
                  buttonText="Explore Body Care"
                  buttonUrl="/body"
                  imgStyles={{ height: 165 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img5}
                  imgAlt="View JAAD Articles"
                  caption="Discover how perspectives in sun protection are evolving"
                  buttonText="VIEW JOURNAL ARTICLES"
                  buttonUrl="https://www.jaad.org/issue/S0190-9622(22)X0002-9"
                  imgStyles={{ height: 165 }}
                  targetOpen="_blank"
                  isExternalLink={true}
                  extUrlClass="external-url"
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default FreeRadicalDefense;
